<template>
  <div class="PageHeader">
    <div
      v-if="showNavigationBar"
      class="NavigationBarContainer">
      <navigation-bar :useCateringContactNumber="useCateringContactNumber"/>
    </div>
    <div
      v-if="isBannerVideo"
      class="BannerVideo">
      <video
        autoplay
        loop
        muted
        playsinline>
        <source
          src="https://storage.googleapis.com/spineproduction/uploads/catering-banner.webm"
          type="video/webm">
        <source
          src="https://storage.googleapis.com/spineproduction/uploads/catering-banner.mp4"
          type="video/mp4">
      </video>
      <div
        class="PageHeaderContentWrapper"
        :class="{'PageHeaderContentWrapperForMenu': bannerForMenu}">
        <div
          v-if="$slots.default"
          class="PageHeaderContent Container"
          :class="{'Dark': bannerSrc}">
          <slot/>
        </div>
      </div>
    </div>
    <banner
      v-else
      :src="bannerSrc"
      :class="bannerClass"
      :minHeight="bannerMinHeight"
      :noGradient="bannerNoGradient"
      :forMenu="bannerForMenu">
      <div
        class="PageHeaderContentWrapper"
        :class="{'PageHeaderContentWrapperForMenu': bannerForMenu}">
        <div
          v-if="$slots.default"
          class="PageHeaderContent Container"
          :class="{'Dark': bannerSrc}">
          <slot/>
        </div>
      </div>
    </banner>
  </div>
</template>

<script>
const Banner = () => import('./Banner')
const NavigationBar = () => import('./NavigationBar')

export default {
  name: 'page-header',
  components: {
    Banner,
    NavigationBar
  },
  props: {
    showNavigationBar: {
      type: Boolean,
      default: true
    },
    useCateringContactNumber: {
      type: Boolean,
      default: true
    },
    isBannerVideo: {
      type: Boolean,
      default: false
    },
    bannerSrc: {
      type: String,
      default: null
    },
    bannerClass: {
      type: String,
      default: null
    },
    bannerMinHeight: {
      type: Number,
      default: null
    },
    bannerNoGradient: {
      type: Boolean,
      default: true
    },
    bannerForMenu: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.PageHeader {
  position: relative;

  .NavigationBarContainer {
    position: relative;
    z-index: 10;

    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .BannerVideo {
    height: 500px;
    overflow: hidden;
    background-color: $ink;

    > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }

    .PageHeaderContentWrapper {
      &:not(.PageHeaderContentWrapperForMenu) {
        position: absolute;
        top: 60px;
        height: calc(100% - 60px);
      }
    }
  }

  .PageHeaderContentWrapper {
    &.Dark {
      color: $white;
    }
    .PageHeaderContent {
      padding: $space-m;

      /deep/ {
        .MenuPricing {
          .GridRow {
            display: flex;
            flex-flow: row wrap;
          }

          .page-header-single-row {
            flex-flow: row nowrap;

            @media #{$xsmall-only} {
              flex-flow: row wrap;

              .GridColumn {
                flex-basis: 50%;
              }
            }
          }
        }
      }
    }

    &:not(.PageHeaderContentWrapperForMenu) {
      position: absolute;
      width: 100%;
      height: 100%;

      @media #{$small-only} {
        position: relative;
      }
      .PageHeaderContent {
        position: relative;
        height: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
</style>
