<template>
  <div class="PageFooter">
    <div class="Footer">
      <div class="LeftColumn">
        <img
          :src="logoLight"
          class="Logo">
      </div>
      <div class="RightColumn">
        <div class="FooterListCollection">
          <div
            v-for="header in headers"
            :key="`header-${header.label}`"
            :width="62.5/headers.length"
            class="FooterList">
            <div class="FooterSectionHeader">
              {{ header.label }}
            </div>
            <ul class="FooterSectionBody">
              <li
                v-for="item in itemByHeader(items, header.linksHeader)"
                :key="item.label">
                <router-link
                  v-if="item.type === 'router'"
                  :to="item.link">
                  {{ item.label }}
                  <span
                    v-if="item.tag"
                    class="FooterLinkTag"
                    v-html="item.tag"/>
                </router-link>
                <a
                  v-else
                  :href="item.link"
                  :target="item.target">
                  {{ item.label }}
                  <span
                    v-if="item.tag"
                    class="FooterLinkTag"
                    v-html="item.tag"/>
                </a>
              </li>
            </ul>
          </div>
          <div
            class="FooterList mailer">
            <div
              id="om-czfwyihhhrpif4esa2nc-holder"
              class="desktop"/>
            <div
              id="om-q2uhvyisea2gxv31rq9u-holder"
              class="mobile"/>
          </div>
        </div>
        <div class="FooterListCollection">
          <div class="HalalLogo">
            <img
              v-if="features.showHalalLogo"
              src="@/assets/images/icon-halal.png"
              alt="MUIS Halal Certified"
              width="40">
          </div>
          <div class="FooterListSmall">
            <a
              v-for="item in itemByHeader(items, 'mini')"
              :key="item.label"
              :href="item.link"
              :target="item.target">
              {{ item.label }}
            </a>
          </div>
          <div class="FooterListSocial">
            <a
              v-for="item in itemByHeader(items, 'social')"
              :key="item.link"
              :href="item.link"
              :target="item.target">
              <fa :icon="item.icon"/>
            </a>
          </div>
        </div>
      </div>
      <div class="BottomRow">
        <h6>
          Revision
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import filter from 'lodash/filter'

const GRAIN_FAQ_MEALS_ON_DEMAND_URL = 'https://grain.notion.site/Frequently-asked-questions-cbd973134d1a47afbe37a17f4ef00118#387d66584d644e6a81e9eb17549bf812'

export default {
  name: 'page-footer',
  data() {
    return {
      headers: [
        { label: this.$t('footer.company.header'), linksHeader: 'company' },
        { label: this.$t('footer.products.header'), linksHeader: 'products' },
        { label: this.$t('footer.help.header'), linksHeader: 'help' }
      ]
    }
  },
  computed: {
    ...mapGetters('user', [
      'features'
    ]),
    items() {
      const items = [
        {
          label: this.$t('footer.company.about'),
          link: '/about',
          header: 'company'
        },
        {
          label: this.$t('footer.company.people'),
          link: `${process.env.VUE_APP_SPINE_URL}/people`,
          header: 'company'
        },
        {
          label: this.$t('footer.company.stories'),
          link: 'https://stories.grain.com.sg/',
          header: 'company'
        },
        {
          label: this.$t('footer.company.deliveryArea'),
          link: '/delivery-area',
          type: 'router',
          header: 'company'
        },
        {
          label: this.$t('footer.company.jobs'),
          link: `${process.env.VUE_APP_SPINE_URL}/jobs`,
          header: 'company'
        },
        {
          label: this.$t('footer.products.meals'),
          link: '/eat',
          header: 'products'
        }
      ]
      if (this.features.showMealPlans) {
        items.push({
          label: this.$t('footer.products.mealPlans'),
          link: '/meal-plans',
          header: 'products',
          tag: this.$t('common.betaTag')
        })
      }
      if (this.features.showCustomNavFooterBeforeCatering && (this.features.showCustomNavFooterBeforeCatering.navs || []).length > 0) {
        this.features.showCustomNavFooterBeforeCatering.navs.map((nav) => {
          items.push({
            label: nav.label,
            link: nav.link,
            target: nav.target !== undefined ? nav.target : '_blank',
            header: 'products'
          })
        })
      }
      items.push({
        label: this.$t('footer.products.catering'),
        link: '/catering',
        header: 'products'
      })
      if (this.features.showCustomNavFooter && (this.features.showCustomNavFooter.navs || []).length > 0) {
        this.features.showCustomNavFooter.navs.map((nav) => {
          items.push({
            label: nav.label,
            link: nav.link,
            target: nav.target !== undefined ? nav.target : '_blank',
            header: 'products'
          })
        })
      }
      items.push(
        {
          label: this.$t('footer.products.gallery'),
          link: 'https://gallery.grain.com.sg/',
          header: 'products'
        },
        {
          label: this.$t('footer.products.giftCards'),
          link: '/gift-cards',
          header: 'products'
        },
        {
          label: this.$t('footer.products.grainSavers'),
          link: '/grain-savers',
          header: 'products'
        },
        {
          label: this.$t('footer.help.faq'),
          link: GRAIN_FAQ_MEALS_ON_DEMAND_URL,
          target: '_blank',
          header: 'help'
        },
        {
          label: this.$t('footer.help.contact'),
          link: '/contact',
          header: 'help'
        },
        {
          label: this.$t('footer.mini.terms'),
          link: '/terms',
          header: 'mini'
        },
        {
          label: this.$t('footer.mini.privacyPolicy'),
          link: '/privacy-policy',
          header: 'mini'
        },
        {
          label: this.$t('footer.mini.downloadIOS'),
          link:
            'https://itunes.apple.com/us/app/grain-never-worry-about-food/id1088932420?ls=1&mt=8',
          header: 'mini'
        },
        {
          label: this.$t('footer.mini.downloadAndroid'),
          link: 'https://play.google.com/store/apps/details?id=com.grain.app',
          header: 'mini'
        },
        {
          icon: ['fab', 'facebook-f'],
          link: 'https://facebook.com/graincomsg',
          header: 'social'
        },
        {
          icon: ['fab', 'instagram'],
          link: 'https://instagram.com/graincomsg',
          header: 'social'
        }
      )
      return items
    },
    logoLight() {
      return require('@/assets/images/grain-logo-light.png')
    }
  },
  methods: {
    itemByHeader(items, header) {
      return filter(items, { header: header })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/spine_sprite";
@import "~@/assets/css/_shared_variables.sass";
.PageFooter {
  position: relative;
  z-index: 1000;
  padding: $space-xl;
  background-color: $ink;
  @extend %body;
  color: $white;

  h6 {
    margin: 0;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .Footer {
    margin: 0 auto;
    max-width: $row-width;
    width: 100%;

    .LeftColumn, .RightColumn {
      box-sizing: border-box;
      display: inline-block;
      width: 100%;
      vertical-align: top;
      padding: 0 $space-xs;
    }
    .LeftColumn {
      @media #{$medium-up} {
        width: 16.66667%;
      }

      @media #{$small-only} {
        padding-bottom: $space-xs;
      }
    }
    .RightColumn {
      @media #{$medium-up} {
        width: 83.33333%;
      }
      .FooterListCollection {
        width: 100%;

        @media #{$large-up} {
          width: 100%;
        }

        &::after {
          display: block;
          clear: both;
          content: "";
        }

        &:not(:last-child) {
          margin-bottom: $space-xl;

          @media #{$small-only} {
            margin-bottom: 0;
          }
        }

        .FooterList {
          display: inline-block;
          width: 100%;
          vertical-align: top;

          @media #{$medium-only} {
            float: left;

            &:last-child {
              float: right;
            }
          }

          @media #{$medium-up} {
            margin-bottom: 30px;

            &:nth-child(odd) {
              width: 35%;
            }

            &:nth-child(even) {
              width: 65%;
            }
          }

          @media #{$large-up} {
            &:nth-child(1) {
              width: 20%;
            }

            &:nth-child(2) {
              width: 20%;
            }

            &:nth-child(3) {
              width: 20%;
            }

            &:nth-child(4) {
              width: 40%;
            }
          }

          &.mailer {
            @media #{$large-up} {
              width: 40%;
            }
          }

          .desktop {
            display: none;

            @media #{$medium-up} {
              display: block;
            }
          }

          .mobile {
            display: block;

            @media #{$medium-up} {
              display: none;
            }
          }
        }
      }
    }
    .BottomRow {
      color: $ink;
      text-align: center;

      @media #{$small-only} {
        display: none;
      }
    }
    @media #{$small-only} {
      text-align: center;
    }
    .Logo {
      display: block;
      height: 50px;
      margin: 0 auto;

      @media #{$small-only} {
        margin-bottom: $space-s;
      }
    }
    .FooterSectionHeader,
    .FooterSectionBody {
      line-height: 1.75rem;
    }
    .FooterSectionHeader {
      @extend %subheading;
      text-transform: uppercase;
      color: $white;
    }
    .FooterSectionBody {
      list-style: none;
      margin: 0;
      padding-left: 0;

      @media #{$medium-down} {
        margin-bottom: $space-l;
      }

      li {
        padding-top: $space-xs;

        &:hover .FooterLinkTag {
          border-color: $gray-dark;
        }
      }

      .FooterLinkTag {
        padding: 1px $space-xxs;
        font-weight: normal;
        vertical-align: text-top;
        border: 1px solid $primary-bg;
        border-radius: $round;
        @extend %small;
      }
    }
    .HalalLogo {
      margin-bottom: $space-s;

      @media #{$small-only} {
        margin-bottom: $space-l;
      }
    }
    .FooterListSmall {
      line-height: 1.85;

      a {
        display: inline-block;
        @extend %small;
      }
      a:not(:last-child) {
        margin-right: $space-s;
      }
    }
    .FooterListSocial {
      margin-top: $space-s;
      margin-left: -$space-xs;
      line-height: 1.85;

      @media #{$small-only} {
        margin-top: $space-l;
      }
      a {
        display: inline-block;
        padding: 0 $space-xs;
      }
    }
    a {
      @extend %body;
      color: $primary-bg;
      text-decoration: none;

      &:hover {
        color: $gray-dark;
      }
    }
  }
}
</style>
