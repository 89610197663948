<template>
  <div
    class="GridRow"
    :class="{'KeepColumnWidth': keepColumnWidth}">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'grid-row',
  props: {
    keepColumnWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
.GridRow {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  /deep/ > .GridColumn {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &:not(.KeepColumnWidth) {
    /deep/ > .GridColumn {
      @media #{$tablet-down} {
        width: 100% !important;

        &.Empty {
          display: none;
        }
      }
    }
  }
}
</style>
